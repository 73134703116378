import type { Timestamp, FormatOptions, Format } from "~/types/node";

export const RE_TIMESTAMP =
  /^((?:\d{1,}:)?\d{1,2}:\d{1,2}[,.]\d{1,3})(?:,| --> )((?:\d{1,}:)?\d{1,2}:\d{1,2}[,.]\d{1,3})(?: (.*))?$/;

export const padLeft = (value: number, length = 2): string =>
  value.toString().padStart(length, "0");

export function formatTimestamp(
  timestamp: number,
  options: FormatOptions = {
    format: "srt",
    isTranslated: false,
    merged: false,
    isShortened: false, // Timestamp format for sbv
  }
) {
  const date = new Date(0, 0, 0, 0, 0, 0, timestamp);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ms = Math.floor(
    timestamp - (hours * 3600000 + minutes * 60000 + seconds * 1000)
  );

  if (!options.isShortened) {
    return `${padLeft(hours)}:${padLeft(minutes)}:${padLeft(seconds)}${
      ["vtt", "sbv"].includes(options.format) ? "." : ","
    }${padLeft(ms, 3)}`;
  } else {
    if (hours > 0) {
      return `${padLeft(hours)}:${padLeft(minutes)}:${padLeft(seconds)}`;
    } else {
      return `${padLeft(minutes)}:${padLeft(seconds)}`;
    }
  }
}

export function validateTimestamp(timestamp: string): boolean {
  const match = timestamp.match(
    /^(?:(\d{1,}):)?(\d{1,2}):(\d{1,2})(?:[,.](\d{0,3}))?$/
  );
  return !!match;
}

export function parseTimestamp(timestamp: string): number {
  const match = timestamp.match(
    /^(?:(\d{1,}):)?(\d{1,2}):(\d{1,2})(?:[,.](\d{0,3}))?$/
  );

  if (!match) {
    throw new Error('Invalid SRT or VTT time format: "' + timestamp + '"');
  }

  const hours = match[1] ? parseInt(match[1], 10) * 3600000 : 0;
  const minutes = parseInt(match[2], 10) * 60000;
  const seconds = parseInt(match[3], 10) * 1000;
  const milliseconds = parseInt(match[4] || "0", 10);

  return (hours + minutes + seconds + milliseconds) / 1000;
}

export function parseTimestamps(value: string): Timestamp {
  const match = RE_TIMESTAMP.exec(value);

  if (!match) {
    throw new Error("Invalid timestamp format");
  }

  const timestamp: Timestamp = {
    startTime: parseTimestamp(match[1]),
    endTime: parseTimestamp(match[2]),
  };

  if (match[3]) {
    timestamp.settings = match[3];
  }

  return timestamp;
}

// export const parseSync = (input: string): NodeList => {
//   const buffer: NodeList = [];
//   const parser = new Parser({
//     push: (node: any) => buffer.push(node),
//   });

//   input
//     .replace(/\r\n/g, "\n")
//     .split("\n")
//     .forEach((line) => parser.parseLine(line));

//   parser.flush();

//   return buffer;
// };
